import components from '../../common/_components';

googletag = googletag || {};
yieldlove_cmd = yieldlove_cmd || {};

const blockName = 'yieldlove-ad';
const googleTagObject = googletag;
const yieldLoveObject = yieldlove_cmd;

const BREAKPOINT = 992;

function parseSizes(data, fallback) {
    let result = fallback;

    try {
        result = JSON.parse(data);
    } catch (e) {}

    return result;
}

components.add(`js-${blockName}`, (el) => {
    const id = window.innerWidth >= BREAKPOINT ? el.getAttribute('data-desktop-id') : el.getAttribute('data-mobile-id');
    const slot = window.innerWidth >= BREAKPOINT ? el.getAttribute('data-desktop-slot') : el.getAttribute('data-mobile-slot');
    const sizes = window.innerWidth >= BREAKPOINT ? parseSizes(el.getAttribute('data-desktop-sizes'), []) : parseSizes(el.getAttribute('data-mobile-sizes'), []);
    const startAuction = el.getAttribute('data-start-auction') === '1';
    const maximumSize = parseInt(el.getAttribute('data-maximum-size'));

    if (maximumSize !== 0 && maximumSize < window.innerWidth) {
        return;
    }

    el.setAttribute('id', id);

    if (el.getAttribute('data-ad-clone') === '0') {
        googleTagObject.cmd.push(() => {
            googleTagObject.defineSlot(slot, sizes, id).addService(googleTagObject.pubads());
            googleTagObject.display(id);
        });
    } else if (el.getAttribute('data-ad-clone') === '1') {
        yieldLoveObject.push(() => {
            YLHH.utils.lazyLoad(id, () => {
                googleTagObject.cmd.push(() => {
                    googleTagObject.defineSlot(slot, sizes, id).addService(googleTagObject.pubads());
                    googleTagObject.display(id);
                });

                YLHH.bidder.cloneUnit(slot, id, startAuction);
            })
        });
    }
});
